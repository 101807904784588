.App {
  display: flex;
  justify-content: center;
  font-family: Arial, sans-serif;
  font-size: 25px;
  flex-direction: column;
}

body {
  margin: 0%;
  padding: 0%;
}
.TitleSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #a8e6ce;
  width: 100%;
  height: 250px;
  color: #e2d2d2;
}

.TitleSection input {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #4ca8ee;
  font-size: 18px;
  padding-left: 10px;
}

.TitleSection button {
  width: 180px;
  margin: 10px;
  height: 60px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 18px;
  padding-left: 10px;
  background-color: #e2d2d2;
}

.TitleSection button:hover {
  cursor: pointer;
}

.DisplaySection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DisplaySection h3,
h4 {
  margin: 7px;
}

.DisplaySection img {
  width: 500px;
}

.lineBreak {
  white-space: pre-wrap;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.card {
  padding: 20px;
  text-align: center;
  max-width: 900px;
  background-color: #e2d2d2;
}
